<template>
  <div class="readme-article">
    <h1 id="班级管理">二、班级管理</h1>
    <h2 id="班级列表">1.班级列表</h2>
    <h3 id="班级信息列表">1.1班级信息列表</h3>
    <p>
      查看班级信息列表，点击详按钮查看详情，支持通过名称，专业，校区等条件筛选班级，员工可添加班级和转班主任。
    </p>
    <p><img src="@/assets/img/education/jw2-2.png" /></p>
    <h3 id="班级详情">1.2班级详情</h3>
    <p>查看班级详情信息，可在详情修修改班级信息以及添加班主任。</p>
    <p><img src="@/assets/img/education/jw2-1.png" /></p>
    <h3 id="添加班级">1.3添加班级</h3>
    <p>员工可在班级管理中添加班级，必须填写班级名称，选择专业和班级状态。</p>
    <p><img src="@/assets/img/education/jw2-4.png" /></p>
    <h3 id="转班主任">1.4转班主任</h3>
    <p>管理员可以把一个班主任负责的部分或全部学生转给另一个班主任。</p>
    <p><img src="@/assets/img/education/jw2-3.png" /></p>
    <h2 id="班主任">2.班主任</h2>
    <p>查看员工作为班主任自己负责的班级，可查看班级信息和转班主任。</p>
    <p><img src="@/assets/img/education/jw2-5.png" /></p>
    <h2 id="班级学员管理">3.班级学员管理</h2>
    <p>查看班级的在读学员信息。</p>
    <p><img src="@/assets/img/education/jw2-6.png" /></p>
  </div>
</template>

<script>
export default {
  name: "Education2-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>